.nav-item{

    .nav-link{
        color: white;
        font-size: 18px;
        font-weight: 500;
    }
    .active{
        color:#FFA62F !important;
    }
}
.selected-tab{
    color: #FFA62F !important;
}