.ql-align-center {
    text-align: center !important;
  }
  .ql-align-right {
    text-align: right !important;
  }
  .ql-align-justify {
    text-align: justify !important;
  }
  .ql-syntax {
    background-color: #f5f5f5;
    color: #d63384;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  pre {
    background-color: #f5f5f5;
    color: #d63384 !important;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  code {
    font-family: monospace;
    color: #d63384;
  }