// .loader-container {
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: #222;
// }

// .loader {
//     position: relative;
//     width: 100px;
//     height: 100px;
// }

// .loader:before,
// .loader:after {
//     content: '';
//     border-radius: 50%;
//     position: absolute;
//     inset: 0;
//     box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
// }

// .loader:after {
//     box-shadow: 0 2px 0 #FF3D00 inset;
//     animation: rotate 2s linear infinite;
// }

// @keyframes rotate {
//     0% {
//         transform: rotate(0)
//     }

//     100% {
//         transform: rotate(360deg)
//     }
// }


.preloader {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    margin: 0 auto
}

.preloader .preloader-circle {
    width: 100px;
    height: 100px;
    position: relative;
    border-style: solid;
    border-width: 3px;
    border-top-color: #FFA62F;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 10;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 5px 0 rgba(4, 14, 14, 0.15);
    box-shadow: 0 1px 5px 0 rgba(14, 18, 19, 0.419);
    background-color: #ffffff;
    -webkit-animation: zoom 2000ms infinite ease;
    animation: zoom 2000ms infinite ease;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.preloader .preloader-circle2 {
    border-top-color: #0078ff
}

.preloader .preloader-img {
    position: absolute;
    top: 50%;
    z-index: 200;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-top: 6px;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.preloader .preloader-img img {
    max-width: 55px
}





.preloader .pere-text strong {
    font-weight: 800;
    color: #dca73a;
    text-transform: uppercase
}

@-webkit-keyframes zoom {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
}

@keyframes zoom {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
}